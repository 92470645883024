import React from "react"
import ServicesBlurb from "./servicesBlurb"
import {
  IoCodeWorking,
  IoPhonePortrait,
  IoAnalyticsOutline,
} from "react-icons/io5"

export default function MoreInfo() {
  return (
    <div className="services">
      <h1 className="header center-text">What Do We Do?</h1>
      <div className="services-blurb">
        <ServicesBlurb additionalClass="one">
          <div className="icon-header">
            <IoCodeWorking
              style={{ height: 100, width: 100, color: "white" }}
            />
          </div>
          <h2>Web Development</h2>
          <p>
            The bread and butter. If you're a small business looking for a
            simple five page website, we have an amazing package for you. Need a
            backend or a database? Get in touch and we'll work something out.
          </p>
        </ServicesBlurb>
        <ServicesBlurb additionalClass="two">
          <div className="icon-header">
            <IoPhonePortrait
              style={{ height: 100, width: 96, color: "white" }}
            />
          </div>
          <h2>Responsive Design</h2>
          <p>
            About half of all websites views come from mobile devices, and we
            consistently get the top scores on the{" "}
            <a href="https://search.google.com/test/mobile-friendly">
              <u>Google Mobile-Friendly Test</u>
            </a>{" "}
            (you can test this website out btw). It'll look good on any device
            and browser.
          </p>
        </ServicesBlurb>
        <ServicesBlurb additionalClass="three">
          <div className="icon-header">
            <IoAnalyticsOutline
              style={{ height: 100, width: 100, color: "white" }}
            />
          </div>
          <h2>Analytics</h2>
          <div className="list" style={{ fontWeight: 400 }}>
            We will:
            <ul>
              <li>Implement all the standard SEO practices</li>
              <li>Use Google Analytics to track how everything's doing</li>
              <li>
                Run diagnostics, such as Google Lighthouse, to make sure your
                website's fully optimized.
              </li>
            </ul>
          </div>
        </ServicesBlurb>
      </div>
    </div>
  )
}
