import React from "react"
import DuoSearcherMock from "../../images/DuosearcherMock.png"
import QinGaoMock from "../../images/QinGaoMock.png"

export default function Portfolio() {
  return (
    <div className="portfolio-container">
      <h1 className="portfolio-header">What Have We Created?</h1>
      <div className="portfolios">
        <div className="portfolio-card">
          <img src={QinGaoMock} alt="QinGaoMock" />
          <h2>Qin Gao Acupuncture</h2>
          <p>
            One of the finest acupuncture clinics located in Sleepy Hollow, New
            York, run by Dr. Qin Gao.
          </p>
          <button className="button is-link">
            <a target="blank" href="https://www.qingaoacupuncture.com/">
              Visit
            </a>
          </button>
        </div>
        <div className="portfolio-card">
          <img src={DuoSearcherMock} alt="DuosearcherMock" />
          <h2>DuoSearcher</h2>
          <p>
            A useful website for looking up statistics for the popular game
            League Of Legends.
          </p>
          <button className="button is-link">
            <a target="blank" href="https://www.duosearcher.com/">
              Visit
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}
