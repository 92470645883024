import React from "react"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import { FcBiotech, FcShipped, FcSupport, FcShare } from "react-icons/fc"

export default function Steps() {
  return (
    <div className="steps">
      <h1 className="center-text" style={{ paddingTop: 30 }}>
        What are the steps?
      </h1>
      <p className="center-text what-do">
        The process is actually really simple. There's a few basic steps:
      </p>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          icon={<FcBiotech />}
          style={{ textAlign: "right" }}
        >
          <h3 className="vertical-timeline-element-title">Design</h3>
          <p>
            Tell us what you want. If you have an idea of what you want, that's
            great! If not, then that's fine too. We'll hash out a design you're
            satisfied with and get started right away!
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          icon={<FcShipped />}
        >
          <h3 className="vertical-timeline-element-title">Deploy</h3>
          <p>
            Once you're satisfied with the website, it'll go live - just sit
            back and watch your new website go live!
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          icon={<FcSupport />}
          style={{ textAlign: "right" }}
        >
          <h3 className="vertical-timeline-element-title">Edit</h3>
          <p>Call or email us anytime you want to make additional changes! </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          icon={<FcShare />}
        >
          <h3 className="vertical-timeline-element-title">Share</h3>
          <p>
            If you like what you see, be sure to recommend us to others. Each
            referral means <b>50% off the next month's cost.</b>
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  )
}
