import React from "react"
import { Link } from "gatsby"

export default function Intro() {
  return (
    <div className="intro-blurb">
      <div className="text">
        <h1>Customizable, modern, and affordable web development</h1>
        <button className="button is-primary is-link is-large">
          <Link to="/contact">Get In Touch!</Link>
        </button>
      </div>
    </div>
  )
}
