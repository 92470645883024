import React from "react"
import { FcCheckmark } from "react-icons/fc"
import { Link } from "gatsby"

export default function Prices({ displayTitle = false, additionalStyle }) {
  return (
    <div className="prices-wrapper" style={additionalStyle}>
      {displayTitle ? (
        <h1 className="center-text header">What Do We Offer?</h1>
      ) : null}
      <div className="prices">
        <div className="prices-card not-dimmed enterprise">
          <h1>Enterprise</h1>
          <p className="center-text">
            <b>Anything. Frontend, Backend, You Name It!</b>
          </p>
          <h2>Contact Us</h2>
          <div className="body">
            <p style={{ padding: "20px 30px 20px 30px" }}>
              If you're looking for something more complicated, this is the
              option for you. Here are some possibilities:
            </p>
            <ul>
              <li>
                <FcCheckmark className="bullet" />
                Customized development plan
              </li>
              <li>
                <FcCheckmark className="bullet" />
                Backend and database services
              </li>
              <li>
                <FcCheckmark className="bullet" />
                Additional web pages
              </li>
              <li>
                <FcCheckmark className="bullet" />
                Work on an existing project
              </li>
            </ul>
            <button
              className="button is-primary"
              style={{ marginTop: 25, marginBottom: 15 }}
            >
              <Link to="/contact">Contact Us</Link>
            </button>
          </div>
        </div>
        <div className="prices-card not-dimmed">
          <h1>Standard</h1>
          <p className="center-text">
            <b>5 Pages</b>
          </p>
          <h2>$150 / Month</h2>
          <div className="body">
            <p style={{ padding: "20px 30px 20px 30px" }}>
              The standard package. Perfect for small business.
            </p>
            <ul>
              <li>
                <FcCheckmark className="bullet" />
                Unlimited edits forever
              </li>
              <li>
                <FcCheckmark className="bullet" />
                Rapid deployment and hosting
              </li>
              <li>
                <FcCheckmark className="bullet" />
                Call, email, or text anytime
              </li>
              <li>
                <FcCheckmark className="bullet" />
                Effective SEO practices
              </li>
              <li>
                <FcCheckmark className="bullet" />
                Google Analytics Included
              </li>
            </ul>
            <button
              className="button is-primary"
              style={{ marginTop: 12, marginBottom: 15 }}
            >
              <Link to="/contact">Contact Us</Link>
            </button>
          </div>
        </div>
        <div className="dimmed">
          <div className="prices-card blogging">
            <h1>Standard + Blogging</h1>
            <p className="center-text">
              <b>5 Pages</b>
            </p>
            <h2>$250 / Month</h2>
            <div className="body">
              <ul>
                <li>
                  <FcCheckmark className="bullet" />
                  Unlimited edits forever
                </li>
                <li>
                  <FcCheckmark className="bullet" />
                  Rapid deployment and hosting
                </li>
                <li>
                  <FcCheckmark className="bullet" />
                  Call, email, or text anytime
                </li>
                <li>
                  <FcCheckmark className="bullet" />
                  Effective SEO practices
                </li>
                <li>
                  <FcCheckmark className="bullet" />
                  Google Analytics Included
                </li>
                <li>
                  <FcCheckmark className="bullet" />
                  <b>Create blog posts anytime</b>
                </li>
              </ul>
              <h4 className="coming-soon">
                Coming Soon!<sup>TM</sup>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
