import React from "react"

export default function ServicesBlurb({ children, additionalClass }) {
  return (
    <div className="card">
      <div className={`card-content ${additionalClass}`}>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}
