import React, { useState, useEffect } from "react"
import Nav from "../components/nav"
import Prices from "../components/prices"
import Intro from "../single_use_components/home/intro"
import Steps from "../single_use_components/home/steps"
import MoreInfo from "../single_use_components/home/moreInfo"
import Wave from "../single_use_components/home/wave"
import Portfolio from "../single_use_components/home/portfolio"
import Footer from "../components/footer"
import MyHelmet from "../components/helmet"
import PageTransition from "gatsby-plugin-page-transitions"

export default function Home() {
  const [isDark, setIsDark] = useState(false)

  useEffect(() => {
    setIsDark(window?.localStorage.getItem("isDark") === "true")
  }, [])

  return (
    <PageTransition>
      <div className={`home-intro ${isDark ? "dark" : ""}`}>
        <MyHelmet />
        <Nav currentPage="home" setIsDark={setIsDark} isDark={isDark} />
        <Wave />
        <Intro />
        <MoreInfo />
        <Steps />
        <Portfolio />
        <Prices displayTitle={true} />
        <Footer />
      </div>
    </PageTransition>
  )
}
